import type EthereumProvider from "@walletconnect/ethereum-provider/dist/types/EthereumProvider";
import { EthereumProvider as WalletConnectEthereumProvider } from "@walletconnect/ethereum-provider";
import { ConfigInstance } from "../Config";

export default class Web3WalletConnectProvider {
    public static init(showQrModal: boolean) {
        return WalletConnectEthereumProvider.init({
            projectId: "16711cbcda49d16c00523776473de373", // REQUIRED your projectId
            chains: [ConfigInstance.blockchain.networkId], // REQUIRED chain ids
            showQrModal: showQrModal,
        });
    }

    public static async connect(): Promise<EthereumProvider> {
        const ethereumProvider = await this.init(true);
        await ethereumProvider.connect();
        return ethereumProvider;
    }
}
