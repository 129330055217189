import type EthereumProvider from "@walletconnect/ethereum-provider/dist/types/EthereumProvider";
import type { WalletProviderKind } from "./LoginManager/TypeDefs";
import detectEthereumProvider from "@metamask/detect-provider";
import Web3NetworkChecker from "./blockchain/Web3MetamaskProvider";
import { ConfigInstance } from "./Config";
import Web3WalletConnectProvider from "./blockchain/Web3WalletConnectProvider";


class Web3WalletProvider {
    private onProviderAccountsChange: (providerKind: WalletProviderKind, addresses: string[]) => void = () => {};
    private kindToProvider: Partial<Record<WalletProviderKind, EthereumProvider>> = {};

    public setOnProviderAccountsChange(callback: (providerKind: WalletProviderKind, addresses: string[]) => void) {
        this.onProviderAccountsChange = callback;
    }

    private unsetProviderAccounts(providerKind: WalletProviderKind) {
        delete this.kindToProvider[providerKind];
        this.onProviderAccountsChange(providerKind, []);
    }

    private setProviderAccounts(
        providerKind: WalletProviderKind,
        addresses: string[],
        ethereumProvider: EthereumProvider
    ) {
        if (addresses.length === 0) {
            this.unsetProviderAccounts(providerKind);
            delete this.kindToProvider[providerKind];
        } else {
            this.kindToProvider[providerKind] = ethereumProvider;
            this.onProviderAccountsChange(providerKind, addresses);
        }
    }

    public async tryObtainExistingEthereumProvider() {
        let mmAccounts = [];
        const ethereum = (window as any).ethereum as EthereumProvider | undefined;
        if (ethereum) {
            try {
                mmAccounts = await ethereum.request<string[]>({ method: "eth_accounts" });
                this.setProviderAccounts("metamask", mmAccounts, ethereum);
            } catch (error: unknown) {
                console.info("Can not retrieve accounts from window.ethereum", error);
            }
            ethereum.on("accountsChanged", accounts => {
                this.setProviderAccounts("metamask", accounts, ethereum);
            });
        }
        if (mmAccounts.length === 0) {
            const wcProvider = await Web3WalletConnectProvider.init(false);
            if (wcProvider.session) {
                const wcAccounts = await wcProvider.request<string[]>({ method: "eth_accounts" });
                this.setProviderAccounts("walletConnect", wcAccounts, wcProvider);
                // wallet connect event when session deleted or removed from phone
                wcProvider.on("session_delete", () => this.unsetProviderAccounts("walletConnect"));
            }
        }
    }

    public getExistingProvider(providerKind: WalletProviderKind): EthereumProvider {
        const ethereumProvider = this.kindToProvider[providerKind];
        if (!ethereumProvider) {
            throw new Error("Tried to use provider " + providerKind + " before initialization!");
        }
        return ethereumProvider;
    }

    private async getSpecificEthereumProvider(
        kind: WalletProviderKind
    ): Promise<EthereumProvider> {
        let ethereumProvider;
        switch (kind) {
            case "metamask": {
                ethereumProvider = (await detectEthereumProvider({ mustBeMetaMask: true })) as EthereumProvider;

                if (!ethereumProvider) {
                    // metamask not detected
                    throw new Error("No metamask installed");
                } else {
                    if (ethereumProvider !== (<any>window).ethereum) {
                        throw new Error("Do you have multiple wallets installed?");
                    }
                }
                break;
            }
            case "walletConnect": {
                ethereumProvider = await Web3WalletConnectProvider.connect();
                ethereumProvider.on("session_delete", () => this.unsetProviderAccounts("walletConnect"));
                break;
            }
            default: {
                throw new Error("Not supported provider kind: " + kind);
            }
        }
        return ethereumProvider;
    }

    async init(kind: WalletProviderKind) {
        const ethereumProvider = await this.getSpecificEthereumProvider(kind);

        const web3Provider = new Web3NetworkChecker({
            ethereumProvider,
            config: ConfigInstance.blockchain,
        });
        const accounts = await web3Provider.ensureConnection();
        this.setProviderAccounts(kind, accounts, ethereumProvider);
        if (accounts.length === 0) {
            throw new Error("No accounts available in provider " + kind);
        }
        return accounts[0];
    }

    async clean() {
        this.unsetProviderAccounts("metamask");
        this.unsetProviderAccounts("walletConnect");
    }
}

// return singleton
export const Web3WalletProviderInstance = new Web3WalletProvider();