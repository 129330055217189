import type { CallAbiContract, TransactionObject } from "@/composables/blockchain/Web3Provider";
import type Web3 from "web3";
import abi from "@/public/abi/abi.json";
import type { AbiItem } from "web3-utils";
import type { Contract } from "web3-eth-contract";
import type { ContractsAbi } from "~/composables/blockchain/ContractAddresses";

export default class AbiAccess {
    private readonly web3: Web3;

    constructor(web3: Web3) {
        this.web3 = web3;
    }

    private static getContract(contract: keyof ContractsAbi, address: string, web3: Web3): Contract {
        return new web3.eth.Contract(abi[contract] as AbiItem[], address);
    }

    public getMethodObj({
        contract,
        methodName,
        methodArguments = [],
        address,
    }: CallAbiContract): TransactionObject {
        const contractObj = AbiAccess.getContract(contract, address, this.web3);
        return contractObj.methods[methodName](...methodArguments);
    }
}
