import Web3 from "web3";
import type { BlockchainConfig } from "~/composables/blockchain/ContractAddresses";
import type { CallAbiContract } from "~/composables/blockchain/Web3Provider";
import AbiAccess from "~/composables/blockchain/AbiAccess";

export class PearlReader {
    constructor (
        private readonly configuration: BlockchainConfig
    ) {
    }

    /**
     * invoke a constant (read-only) method
     *
     * @return - method-specific data format
     */
    public async callContractMethod<T>(callParams: CallAbiContract): Promise<T> {
        const httpProvider = new Web3.providers.HttpProvider(this.configuration.nodeUrl);
        const web3 = new Web3(httpProvider);
        const transactionObj = await new AbiAccess(web3).getMethodObj(callParams);

        return transactionObj.call();
    }

    public async lp(): Promise<string> {
        return this.callContractMethod<string>({
            contract: "pearlStore",
            address: this.configuration.contracts.pearlStore,
            methodName: "lp",
        });
    }

    public async lockPeriod() {
        return this.callContractMethod<string>({
            contract: "pearlYield",
            address: this.configuration.contracts.pearlYield,
            methodName: "lockPeriod",
            methodArguments: [],
        });
    }

    public async unlockable(address: string, index: number,) {
        const amount = await this.callContractMethod<string>({
            contract: "pearlYield",
            address: this.configuration.contracts.pearlYield,
            methodName: "unlockable",
            methodArguments: [address, index],
        });

        return Web3.utils.fromWei(amount, "ether");
    }

    public async unlockPeriod() {
        return this.callContractMethod<string>({
            contract: "pearlYield",
            address: this.configuration.contracts.pearlYield,
            methodName: "unlockPeriod",
            methodArguments: [],
        });
    }

    public async availableTokens(address: string, tokenAddress: string): Promise<string> {
        const amount = await this.callContractMethod<string>({
            contract: "erc20",
            address: tokenAddress,
            methodName: "balanceOf",
            methodArguments: [address],
        });

        return Web3.utils.fromWei(amount, "ether");
    }

    public async allowance(address: string, currencyAddress: string): Promise<string> {
        const contractAddr = this.configuration.contracts.pearlStore;

        const amount = await this.callContractMethod<string>({
            contract: "erc20",
            address: currencyAddress,
            methodName: "allowance",
            methodArguments: [address, contractAddr],
        });

        return Web3.utils.fromWei(amount, "ether");
    }

    public async totalStake(): Promise<string> {
        const amount = await this.callContractMethod<string>({
            contract: "pearlYield",
            address: this.configuration.contracts.pearlYield,
            methodName: "totalSupply",
        });

        return Web3.utils.fromWei(amount, "ether");
    }

    public async tokenMaxTotalSupply(): Promise<string> {
        const amount = await this.callContractMethod<string>({
            contract: "pearl",
            address: this.configuration.contracts.pearl,
            methodName: "totalSupply",
        });

        return Web3.utils.fromWei(amount, "ether");
    }

    public async staked(address: string): Promise<string> {
        const amount = await this.callContractMethod<string>({
            contract: "pearlYield",
            address: this.configuration.contracts.pearlYield,
            methodName: "balanceOf",
            methodArguments: [address],
        });

        return Web3.utils.fromWei(amount, "ether");
    }

    public async getLocks(address: string): Promise<string> {
        const amount = await this.callContractMethod<string>({
            contract: "pearlYield",
            address: this.configuration.contracts.pearlYield,
            methodName: "getLocks",
            methodArguments: [address],
        });

        return Web3.utils.fromWei(amount, "ether");
    }

    public async earned(address: string): Promise<string> {
        const amount = await this.callContractMethod<string>({
            contract: "pearlYield",
            address: this.configuration.contracts.pearlYield,
            methodName: "earned",
            methodArguments: [address],
        });

        return Web3.utils.fromWei(amount, "ether");
    }

    public async getRewardRate(): Promise<string> {
        const amount = await this.callContractMethod<string>({
            contract: "pearlStore",
            address: this.configuration.contracts.pearlStore,
            methodName: "rewardRate",
        });

        return Web3.utils.fromWei(amount, "ether");
    }

    public async saleAmount(): Promise<string> {
        const amount = await this.callContractMethod<string>({
            contract: "pearlStore",
            address: this.configuration.contracts.pearlStore,
            methodName: "saleAmount",
        });

        return Web3.utils.fromWei(amount, "ether");
    }

    public async getTransactionInfo(hash: string) {
        const httpProvider = new Web3.providers.HttpProvider(this.configuration.nodeUrl);
        const web3 = new Web3(httpProvider);
        return web3.eth.getTransaction(hash);
    }

    public async getTransactionReceipt(hash: string) {
        const httpProvider = new Web3.providers.HttpProvider(this.configuration.nodeUrl);
        const web3 = new Web3(httpProvider);
        return web3.eth.getTransactionReceipt(hash);
    }

    public async getLastBlock() {
        const httpProvider = new Web3.providers.HttpProvider(this.configuration.nodeUrl);
        const web3 = new Web3(httpProvider);
        return web3.eth.getBlockNumber();
    }
}
