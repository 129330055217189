import Web3 from "web3";
import { sleep } from "@/composables/utils/utils";
import type EthereumProvider from "@walletconnect/ethereum-provider/dist/types/EthereumProvider";
import type { BlockchainConfig } from "./ContractAddresses";
// import { ShowLoader } from "@/common/decorators/ShowLoader";

export class BlockchainNetworkError extends Error {
    constructor(public readonly actual: string, public readonly expected: string) {
        super(`Wrong network selected in wallet. Expected ${expected}, but actual is ${actual}`);
    }
}

export interface Params {
    ethereumProvider: EthereumProvider;
    config: BlockchainConfig;
}

export default class Web3NetworkChecker {
    public constructor(private readonly params: Params) {
    }

    get web3() {
        return new Web3(this.params.ethereumProvider);
    }

    public async ensureConnection() {
        const accounts = await this.params.ethereumProvider.request<string[]>({ method: "eth_requestAccounts" });
        await this.ensureCorrectNetwork();
        return accounts;
    }

    private async ensureCorrectNetwork() {
        // some wallets hide network settings from a user
        if (!(await this.isExpectedNetwork())) {
            await this.switchNetwork();
            await sleep(200); // add delay because wallets reject fast calls
        }
    }

    public static chainIdToName(chainId: number): string {
        switch (chainId) {
            case 56: return "Binance Smart Chain Mainnet";
            case 97: return "Binance Smart Chain Testnet";
            default: return "unknown chain";
        }
    }

    public async isExpectedNetwork(): Promise<boolean> {
        const whenNetworkId = this.web3.eth.net.getId();
        const whenTimeout = new Promise((resolve, reject) => setTimeout(() => reject("Error on checking network id"), 10000));
        const networkId = await Promise.race([whenNetworkId, whenTimeout]);
        return this.params.config.networkId === networkId;
    }

    /**
     * Add blockchain network to the user wallet
     * @see https://docs.metamask.io/guide/rpc-api.html#wallet-addethereumchain
     */
    public async addNetwork(): Promise<unknown> {
        const { networkId, blockScanUrl, nodeUrl } = this.params.config;
        const symbol = "BNB";

        console.log(networkId, Web3.utils.toHex(networkId), blockScanUrl, nodeUrl, symbol);
        return this.params.ethereumProvider.request({
            method: "wallet_addEthereumChain",
            params: [
                {
                    chainId: Web3.utils.toHex(networkId),
                    chainName: Web3NetworkChecker.chainIdToName(networkId),
                    nativeCurrency: { name: symbol, symbol: symbol, decimals: 18 },
                    rpcUrls: [nodeUrl + "/"], // must end with /
                    blockExplorerUrls: [blockScanUrl + "/"],
                },
            ],
        });
    }

    /**
     * Switch blockchain network in the user wallet
     * @see https://docs.metamask.io/guide/rpc-api.html#wallet-switchethereumchain
     */
    public async switchNetwork(): Promise<unknown> {
        try {
            return await this.params.ethereumProvider.request({
                method: "wallet_switchEthereumChain",
                params: [{ chainId: Web3.utils.toHex(this.params.config.networkId) }],
            });
        } catch (e: unknown) {
            // network not present in metamask
            if ((<any>e).code === 4902) return await this.addNetwork();
            throw e;
        }
    }

    public static async ensureCorrectNetwork(
        ethereumProvider: EthereumProvider,
        config: BlockchainConfig
    ) {
        await new Web3NetworkChecker({
            ethereumProvider, config
        }).ensureCorrectNetwork();
    }

    public static async checkSelectedNetwork(web3: Web3, neededId: number): Promise<void> {
        const networkType = await web3.eth.net.getNetworkType();
        const networkId = await web3.eth.net.getId();

        if (networkId != neededId) {
            const neededComment = Web3NetworkChecker.chainIdToName(neededId);
            throw new BlockchainNetworkError(
                `${networkId} (${Web3NetworkChecker.chainIdToName(networkId) || " (" + networkType + "))"}`,
                `${neededId} (${neededComment})`
            );
        }
    }

    public async checkSelectedNetwork(): Promise<void> {
        const web3 = this.web3;
        const neededId = this.params.config.networkId;
        await Web3NetworkChecker.checkSelectedNetwork(web3, neededId);
    }
}
